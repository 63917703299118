@import url('https://fonts.cdnfonts.com/css/butter-cup');

.video-section {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow-y: scroll/auto;
  overflow-x: visible;
  border: none !important;
}
.sq-card-wrapper {
  display: block !important;
  min-width: 100%;
  width: 100%;
  align-items: flex-start;
}
body {
  overflow-x: hidden;
}

/* Styling for the background video */
.background-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  border: none !important;
  object-fit: cover;
}

.beg h2 {
  text-align: center;
  font-family: Montserrat;
  font-size: 60px;
  font-weight: 800;
  color: #fff;
}

.col-lg-4 img {}

.img2 {
  width: 100%;
  height: 350px;

}

article {
  --hover: 0;
}

article:hover {
  --hover: 1;
}

.about h3 {
  font-family: Montserrat;
  font-size: 50px;
  font-weight: 800;
  text-align: center;
  color: #fff;

}

.text {
  font-family: Hello Holiday;
  font-size: 20px;
  font-weight: 400;
  line-height: 27px;
  color: #fff;
}

.beg h3 {
  font-family: Poiret One;
  font-size: 60px;
  font-weight: 400;
  line-height: 105.3px;
  color: #fff;
  text-align: center;

}

.beg h5 {
  font-family: Montserrat;
  font-size: 25px;
  font-weight: 400;

  text-align: center;
  color: #EC909F;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  text-align: center;
}

/* Styling for the top section */
.top-section {
  /* position: absolute;
  top: 10px; */
  width: 100%;

}

.logo {
  width: 100px;

  margin-bottom: 10px;
  /* Space between logo and header */
}

/* Custom dropdown on hover */
.navbar-nav .dropdown:hover .dropdown-menu {
  display: block;
}

.nav-link:focus,
.nav-link:hover {
  color: #ffff;
}

.dropdown-menu {
  position: absolute;
  z-index: 1000;
  display: none;
  min-width: 10rem;
  padding: .5rem 0;
  margin: 0;
  font-size: 1rem;
  color: #ffff;
  text-align: left;
  list-style: none;
  background-color: #EC909F;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, .15);
  border-radius: .25rem;
}

.header ul li {
  list-style: none;
  margin-left: 22px;
}

.header ul li a {
  color: white;
  text-decoration: none;
  font-size: 18px;
}

/* Styling for the middle section */
.middle-section {
  margin-top: 20px;
}

.middle-section h2 {
  color: #fff;
  font-family: Montserrat;
  font-size: 90px;
  font-weight: 800;
  line-height: 109.71px;


}

.middle-section .button {
  padding: 10px 20px;
  background-color: #e43c5c;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

.para {
  font-family: ""
}

.button {
  padding: 5px 10px;
  background-color: #151515D1;
  color: #EC909F;
  border: 1px solid #EC909F;
  border-radius: 5px;
  text-decoration: none;
  cursor: pointer;
  font-size: 16px;
}

.header {
  background: #2E2E2ED9;
  width: fit-content;
  gap: 0px;
  border-radius: 23px;
  padding: 5px;
  margin: auto;
  padding-left: 0.5rem;
  padding-right: 1rem;

}

.header ul {
  margin-bottom: 0rem;
}

.header li {
  margin: auto;
}



.para3 {
  font-family: Montserrat;
  font-size: 14px;

}

.shop {
  background: none;
  color: #fff;
  border-radius: 13px;
  font-size: 10px;
  padding: 5px;
  width: 100px;
}

.para2 {
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 250;
  text-align: center;
}

.parent {
  display: flex;
  justify-content: space-between;
}

.word {
  font-family: 'Montserrat';
  font-size: 45px;
  font-weight: 800;
  line-height: 54.86px;
  text-align: left;

}

.footer {

  padding: 2rem 0;
}

.mainfooter {
  display: flex;
}

@media (max-width:991px) {
  .mainfooter {
    display: block;
  }

  nav ul {
    display: grid;
  }
}

nav {
  display: flex;
  justify-content: end;
}

nav ul {
  display: flex;
}

.footer p {
  margin-bottom: 0.5rem;
  font-size: 14px;
  color: #777;
}

.footer li a {
  color: #777;
  text-decoration: none;
  list-style: none;
}

/* 
.begins {
  margin-top: -60px;
} */

form {
  margin-top: 30px;
  /* background: #fff; */
  padding: 6px 10px;
  position: relative;
  border-radius: 50px;
  text-align: left;
  /* border: 1px solid #f7c2cc; */
}

.footer ul li {
  text-decoration: none;
  list-style: none;
}


form input[type=submit] {
  position: absolute;
  top: 0;
  right: -2px;
  bottom: 0;
  border: 0;
  background: none;
  font-size: 15px;
  padding: 0 22px;
  background: #e43c5c;
  color: #fff;
  transition: 0.3s;
  border-radius: 50px;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
}

.contact {
  border-radius: 23px;
  background-color: #fff;
  color: #301010;
  width: 100px;
}

.desc {
  background-color: #383030;
  color: #fff;
  padding: 13px;
}

.shop {
  border: 2px solid #EA8998;
}

.parent {
  background-color: #111111;
  padding-left: 20px;
  padding-right: 20px;
}

.begparent {
  margin-top: -20px;
}

.begin {
  border: 1px solid #D67587;
  border-radius: 10px;
  padding: 20px;
  width: -webkit-fill-available;

}

.begins {
  border: 1px solid #D67587;
  border-radius: 10px;
  padding: 20px;
  width: -webkit-fill-available;
  /* margin: auto; */
  margin-top: -90px;
}

.begins h4 {
  font-family: Poiret One;
  font-size: 60px;
  font-weight: 400;
  width: -webkit-fill-available;
  color: #fff;
  text-align: start;
}

.begin h4 {
  font-family: Poiret One;
  font-size: 60px;
  font-weight: 400;
  width: -webkit-fill-available;
  color: #fff;
  text-align: start;
}

.desc h2 {
  color: #EA8998;
  font-family: "Lobster", sans-serif;
}

.img {
  height: 290px;
}

.para1 {
  font-family: Montserrat;
  font-size: 20px;
  font-weight: 250;
  line-height: 24.59px;
  text-align: center;
}

/* body {
  font-family: sans-serif;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: space-around;
  font-size: 12px;
  background: linear-gradient(195deg, #ae6be0, #ddd);
} */
.wrapper {
  position: relative;
  flex: 0 0 auto;
  padding: 53.3807829181px 16px;
  background: #222;
  border-radius: 20px;
  box-shadow: 0 7px 0 0 #555;
}

.wrapper:before {
  content: '';
  position: absolute;
  transform: translate(-50%, -50%);
  background: #777;
  z-index: 1;
  top: 6%;
  left: 50%;
  width: 30%;
  height: 1%;
  border-radius: 500px;
}

.wrapper:after {
  content: '';
  position: absolute;
  transform: translate(-50%, 50%);
  bottom: 6%;
  left: 50%;
  width: 10%;
  height: 5%;
  border-radius: 500px;
  border: 4px solid #666;
}

.app {
  position: relative;
  background: #fff;
  flex: 0 0 auto;
  width: 200px;
  height: 355.871886121px;
  margin: 5px;
  overflow: hidden;
}

.nav .nav-bar {
  display: flex;
  flex-flow: row;
  position: relative;
  z-index: 3;
}

.nav .logo {
  flex: 1 1 100%;
}

.nav .nav-btn {
  display: flex;
  flex-flow: column;
  align-items: center;
  position: relative;
  cursor: pointer;
  flex: 0;
  padding: 5px;
}

.nav .nav-btn .btn-bar {
  position: relative;
  width: 1.34em;
  height: 3px;
  border-radius: 5px;
  background: #444;
}

.nav .nav-btn .btn-bar.menu:not(:first-child) {
  margin-top: 3px;
}

.nav .nav-btn .btn-bar.close {
  transform-origin: center center;
  position: absolute;
  margin-top: -1.5px;
  opacity: 0;
  top: 50%;
}

.nav .nav-content {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
}

.nav .nav-content .background {
  position: absolute;
  display: flex;
  flex-flow: column;
  align-items: stretch;
  top: 0;
  left: 0;
  right: 0;
  bottom: -2px;
}

.nav .nav-content .portion {
  background: #c796ec;
}

.nav .nav-content .portion:not(:first-child) {
  margin-top: -1px;
}

.nav.open .nav-bar .nav-btn .btn-bar.menu {
  opacity: 0;
}

.nav.open .nav-bar .nav-btn .btn-bar.close {
  opacity: 1;
}

.wrapper:nth-child(1) .app .nav {
  /*/
 ////////////////////////////////// // Region: Nav Animations //////////////////////////////////
 /*/
  /*/
 ////////////////////////////////// // Region: Initial (Closed) States //////////////////////////////////
 /*/
  /*/
 ////////////////////////////////// // Region: Expanded (Open) States //////////////////////////////////
 /*/
  /*/
 ////////////////////////////////// // Region: Transition -> Menu Opening //////////////////////////////////
 /*/
  /*/
 ////////////////////////////////// // Region: Transition -> Menu Closing //////////////////////////////////
 /*/
}

.wrapper:nth-child(1) .app .nav .btn-bar.menu:nth-child(even) {
  width: 1.8em;
}

.wrapper:nth-child(1) .app .nav .btn-bar.close:not(:last-child) {
  transform: rotate(45deg) translateX(-250%);
}

.wrapper:nth-child(1) .app .nav .btn-bar.close:last-child {
  transform: rotate(-45deg) translateX(250%);
}

.wrapper:nth-child(1) .app .nav .nav-content .background .portion {
  flex: 1 1 100%;
}

.wrapper:nth-child(1) .app .nav .nav-content .background .portion:nth-child(odd) {
  transform: translateX(-100%);
}

.wrapper:nth-child(1) .app .nav .nav-content .background .portion:nth-child(even) {
  transform: translateX(100%);
}

.wrapper:nth-child(1) .app .nav.open .nav-btn .btn-bar.menu:nth-child(odd) {
  transform: translateX(-400%);
}

.wrapper:nth-child(1) .app .nav.open .nav-btn .btn-bar.menu:nth-child(even) {
  transform: translateX(200%);
}

.wrapper:nth-child(1) .app .nav.open .nav-btn .btn-bar.close:not(:last-child) {
  transform: rotate(45deg);
}

.wrapper:nth-child(1) .app .nav.open .nav-btn .btn-bar.close:last-child {
  transform: rotate(-45deg);
}

.wrapper:nth-child(1) .app .nav.open .nav-content {
  z-index: 1;
}

.wrapper:nth-child(1) .app .nav.open .nav-content .background .portion {
  transform: translateX(0%);
}

.wrapper:nth-child(1) .app .nav.open .btn-bar.menu {
  transition: transform 0.3s ease-in-out, opacity 0.15s ease-in-out 0.15s;
}

.wrapper:nth-child(1) .app .nav.open .btn-bar.close {
  transition: transform 0.2s ease-in-out 0.3s, opacity 0.05s ease-in-out 0.3s;
}

.wrapper:nth-child(1) .app .nav.open .nav-content {
  transition: z-index 0.01s ease-in-out 0.3s;
}

.wrapper:nth-child(1) .app .nav.open .nav-content .background .portion {
  transition: transform 0.2s ease-in-out 0.3s;
}

.wrapper:nth-child(1) .app .nav .btn-bar.menu {
  transition: transform 0.3s ease-in-out 0.2s, opacity 0.15s ease-in-out 0.2s;
}

.wrapper:nth-child(1) .app .nav .btn-bar.close {
  transition: transform 0.2s ease-in-out, opacity 0.05s ease-in-out 0.2s;
}

.wrapper:nth-child(1) .app .nav .nav-content {
  transition: z-index 0.01s ease-in-out 0.2s;
}

.wrapper:nth-child(1) .app .nav .nav-content .background .portion {
  transition: transform 0.2s ease-in-out;
}

.wrapper:nth-child(2) .app .nav {
  /*/
 ////////////////////////////////// // Region: Nav Animations //////////////////////////////////
 /*/
  /*/
 ////////////////////////////////// // Region: Initial (Closed) States //////////////////////////////////
 /*/
  /*/
 ////////////////////////////////// // Region: Expanded (Open) States //////////////////////////////////
 /*/
  /*/
 ////////////////////////////////// // Region: Transition -> Menu Opening //////////////////////////////////
 /*/
  /*/
 ////////////////////////////////// // Region: Transition -> Menu Closing //////////////////////////////////
 /*/
}

.wrapper:nth-child(2) .app .nav .nav-btn {
  align-items: flex-end;
}

.wrapper:nth-child(2) .app .nav .btn-bar.menu:nth-child(1) {
  transform-origin: top right;
  transform: rotate(-45deg);
  width: 1.8em;
}

.wrapper:nth-child(2) .app .nav .btn-bar.menu:nth-child(2) {
  transform: rotate(-45deg) translate(0, -4px);
}

.wrapper:nth-child(2) .app .nav .btn-bar.menu:nth-child(3) {
  transform: rotate(-45deg) translate(0, 1px);
  width: 0.8em;
}

.wrapper:nth-child(2) .app .nav .btn-bar.close:not(:last-child) {
  transform: rotate(45deg) translateX(-250%);
}

.wrapper:nth-child(2) .app .nav .btn-bar.close:last-child {
  transform: rotate(-45deg) translateX(250%);
}

.wrapper:nth-child(2) .app .nav .nav-content .background {
  transform-origin: center;
  transform: rotate(-45deg) scale(2, 1.2);
}

.wrapper:nth-child(2) .app .nav .nav-content .background .portion {
  flex: 1 1 100%;
}

.wrapper:nth-child(2) .app .nav .nav-content .background .portion:nth-child(odd) {
  transform: translateX(-100%);
}

.wrapper:nth-child(2) .app .nav .nav-content .background .portion:nth-child(even) {
  transform: translateX(100%);
}

.wrapper:nth-child(2) .app .nav.open .nav-btn .btn-bar:nth-child(1) {
  transform: rotate(-45deg) translateX(-400%);
}

.wrapper:nth-child(2) .app .nav.open .nav-btn .btn-bar:nth-child(2) {
  transform: rotate(-45deg) translate(0, -4px) translateX(200%);
}

.wrapper:nth-child(2) .app .nav.open .nav-btn .btn-bar:nth-child(3) {
  transform: rotate(-45deg) translate(0, 1px) translateX(200%);
}

.wrapper:nth-child(2) .app .nav.open .nav-btn .btn-bar.close:not(:last-child) {
  transform: rotate(45deg);
}

.wrapper:nth-child(2) .app .nav.open .nav-btn .btn-bar.close:last-child {
  transform: rotate(-45deg);
}

.wrapper:nth-child(2) .app .nav.open .nav-content {
  z-index: 1;
}

.wrapper:nth-child(2) .app .nav.open .nav-content .background .portion {
  transform: translateX(0%);
}

.wrapper:nth-child(2) .app .nav.open .btn-bar.menu {
  transition: transform 0.3s ease-in-out, opacity 0.15s ease-in-out 0.15s;
}

.wrapper:nth-child(2) .app .nav.open .btn-bar.close {
  transition: transform 0.5s ease-in-out 0.3s, opacity 0.125s ease-in-out 0.3s;
}

.wrapper:nth-child(2) .app .nav.open .nav-content {
  transition: z-index 0.01s ease-in-out 0.3s;
}

.wrapper:nth-child(2) .app .nav.open .nav-content .background .portion {
  transition: transform 0.5s ease-in-out 0.3s;
}

.wrapper:nth-child(2) .app .nav .btn-bar.menu {
  transition: transform 0.3s ease-in-out 0.5s, opacity 0.15s ease-in-out 0.5s;
}

.wrapper:nth-child(2) .app .nav .btn-bar.close {
  transition: transform 0.5s ease-in-out, opacity 0.125s ease-in-out 0.5s;
}

.wrapper:nth-child(2) .app .nav .nav-content {
  transition: z-index 0.01s ease-in-out 0.5s;
}

.wrapper:nth-child(2) .app .nav .nav-content .background .portion {
  transition: transform 0.5s ease-in-out;
}



@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.section-navigate {
  position: fixed;
  top: 50%;
  right: 1em;
  transform: translateY(-50%);
  z-index: 1031;
  opacity: 1;
  transition: opacity .05s ease;
}

.section-navigate.is--inactive {
  opacity: 0;
}

.section-navigate__items:before {
  content: '';
  width: 1px;
  height: 180%;
  background: linear-gradient(transparent 0, white 15%, white 85%, transparent);
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.section-navigate__link {
  text-decoration: none;
  position: relative;
}

.section-navigate__link:before {
  content: '\25CF';
  width: 20px;
  height: 20px;
  font-size: 13px;
  line-height: 26px;
  color: white;
  position: relative;
  left: 2px;
}

.section-navigate__link:hover .section-navigate__name {
  opacity: 1;
}

.section-navigate__link.is--active:before {
  content: '\25CF';
  width: 20px;
  height: 20px;
  font-size: 20px;
  left: 0;
}

.section-navigate__name {
  position: absolute;
  top: 5px;
  right: 23px;
  color: white;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 100;
  letter-spacing: 2px;
  opacity: 0;
  transition: opacity .5s ease;
}

.section {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-size: 6em;
  text-transform: uppercase;
  font-weight: bold;
  color: white;
  transition: opacity .4s ease;
}

.section.is--inactive {
  opacity: 0;
}

.section__first {
  position: relative;
}

.section__first-content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(black, .5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.payment-page {
  display: flex;
  justify-content: space-between;
  padding: 20px;
  background-color: #000000;
  color: #fff;
  height: auto;
}

.product-info {
  width: 50%;
  padding: 20px;
  background-color: #000000;
  color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.product-info img {
  width: 100%;
  /* height: 500px; */
}


.payment-form {
  width: 50%;
  padding: 20px;
  background-color: #000000;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.payment-form form {
  display: flex;
  flex-direction: column;
}

.payment-form .form-group {
  margin-bottom: 20px;
}

.payment-form .form-control {
  padding: 10px;
  border: 1px solid #ced4da;
  border-radius: 4px;
}

.payment-form .btn-primary {
  background-color: #007bff;
  border: none;
  padding: 10px;
  color: white;
  cursor: pointer;
}

.payment-form .btn-primary:disabled {
  background-color: #6c757d;
}





@media screen and (min-width: 700px) {

  figure {
    background: #000000;
    width: 100%;
    height: 100vh;
    margin: 0 auto 10vh 0;
    position: sticky;
    top: 0;
    overflow: hidden;
    box-shadow: 4px -4px 8px rgba(0, 0, 0, .4);
  }



  figure:nth-of-type(2n)::after {
    right: 45%;
    left: 5vmin;
  }


  figure:nth-of-type(1),
  section:nth-of-type(1) {
    margin: 0 0 10vh 0;
    width: 100%;
  }

  figure:nth-of-type(2n) {
    margin: 0 0 10vh auto;
    box-shadow: -4px -4px 8px rgba(0, 0, 0, .4);
  }


}

.wrapper {
  display: flex;
  width: 90%;
  justify-content: space-around;
}

.buttons.selected {
  background-color: #EA8998;
}

.cards {

  height: 70vh;
  border-radius: 15px;
  padding: 1.5rem;

  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.4s ease-out;
  box-shadow: 0px 7px 10px rgba(0, 0, 0, 0.5);
}

.cards:hover {
  transform: translateY(20px);
}

.cards:hover:before {
  opacity: 1;
}

.cards:hover .info {
  opacity: 1;
  transform: translateY(0px);
}

.cards:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100%;
  border-radius: 15px;
  background: rgba(0, 0, 0, 0.6);
  z-index: 2;
  transition: 0.5s;
  opacity: 0;
}

.cards img {
  /* width: 100%;
  height: 100%; */
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 15px;
}

.cards .info {
  position: relative;
  z-index: 3;
  color: white;
  opacity: 0;
  transform: translateY(30px);
  transition: 0.5s;
}

.cards .info h1 {
  margin: 0px;
}

.cards .info p {
  letter-spacing: 1px;
  font-size: 15px;
  margin-top: 8px;
}

.cards .info button {
  padding: 0.6rem;
  outline: none;
  border: none;
  border-radius: 3px;
  background: white;
  color: black;
  font-weight: bold;
  cursor: pointer;
  transition: 0.4s ease;
}

.button:hover {
  color: #EC909F;
}

.buttons {
  background: none;
  padding: 13px;
  border-radius: 13px;
  border: 1px solid #EA8998;
}

.cards h5 {
  font-family: Poiret One;
  font-size: 30px;
}
.cards h6 {
  font-family: Poiret One;
  font-size: 25px;
}
.navbar-nav {
  display: flex;
  flex-direction: row !important;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.carddesc{
  margin-top: 100px;
  width: 50%;
  text-align: start;
}
.carddesc p{
  width: 70%;
}
@media (max-width: 991px) {
  .middle-section h2 {
    font-size: 40px;
    font-weight: 800;
    line-height: 50px;
  }
  .descparent{
    margin: auto;
    display: flex;
    justify-content: center;
  }
.carddesc{
  margin-top: 0;
  text-align: center;
  width: 100%;
}
.carddesc p{
  width: 80%;
  text-align: center;
  margin: auto;
  margin-top: 10px;
}
.descparent{
  margin-top: 20px;
}
.payment-form{
  width: 100%;
}
.payment-page{
  display: block;
}
.product-info{
  width: 100%;
}
  .video-section {
    position: relative;
    width: 100%;
    height: auto;
    overflow-y: scroll/auto;
    overflow-x: visible;
    border: none !important;
  }

  .middle-section {
    margin-top: 0px;
  }

  .cards {
    height: 65vh;
    border-radius: 15px;
    padding: 1.5rem;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.4s ease-out;
    box-shadow: 0px 7px 10px rgba(0, 0, 0, 0.5);
  }

  .begins {
    border: 1px solid #D67587;
    border-radius: 10px;
    padding: 20px;
    width: 90% !important;
    margin: auto;
  }

  .desc {
    width: 90%;
    margin: auto;
  }
  ol, ul {
    padding-left: 0rem;
}
  .begin {
    border: 1px solid #D67587;
    border-radius: 10px;
    padding: 20px;
    width: 90% !important;
    margin: auto;
  }

  nav {
    display: flex;
    justify-content: start;
  }

  .header ul li {
    list-style: none;
    margin-left: 15px;
  }
  .begins h4 {
    font-family: Poiret One;
    font-size: 50px;
    font-weight: 400;
    width: -webkit-fill-available;
    color: #fff;
    text-align: start;
  }
  /* .cards img {
    width: 100%;
    height: 440px;
  } */

  .navbar-nav {
    display: flex;
    flex-direction: row !important;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
    flex-wrap: wrap;
  }
}